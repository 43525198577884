import gql from 'graphql-tag';

export const playerSelfExclusionDetailsFragment = gql`
  fragment PlayerSelfExclusionDetails on Player {
    selfExclusionDetails {
      id
      configuredAt
      exclusionEndsAt
      willBeCancelledAt
      pending {
        exclusionEndsAt
        configuredAt
        activeFrom
      }
    }
  }
`;
